<template>
  <div style="display: inline-block;vertical-align: middle;height: 100%;width: 100%">
    <v-chart className="Lines" :option="option"/>
  </div>
</template>

<script>
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {LineChart ,LinesChart} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
    ToolboxComponent,
} from "echarts/components";
import VChart, {THEME_KEY} from "vue-echarts";
import {ref, defineComponent} from "vue";

use([
  CanvasRenderer,
  LinesChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart
]);

export default defineComponent({
  name: "HelloWorld",
  props:{
    chartData:{
      required:true
    },
    x_Axis:{},
    _legend:{},
  },
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "light"
  },
  setup() {
    const option = ref({
      title: {
        text: ''
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {

      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      xAxis: {},
      yAxis: {
        type: 'value'
      },
      series: [


      ]
    });

    return {option};
  },
  mounted() {
    this.option.xAxis=this.x_Axis
    this.option.legend=this._legend
    this.option.series=this.chartData;
  },
  watch:{
    "chartData":{
      deep:true,
      handler(){
        this.option.series=this.chartData;
      }
    }
  }
});
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
